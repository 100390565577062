"use client";

import { APIErrorResponse, APISuccessResponse } from "../type";
import { APIError } from "../utils";

export type ClientFetcherProps = {
  url: string;
  options: RequestInit;
};

export async function clientFetcher<T>({ url, options }: ClientFetcherProps) {
  return await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      // DO NOT REMOVE THIS, USED By CLOUDFLARE TO SEND THE CORRECT ENCODING
      "Accept-Encoding": "gzip",
    },
  })
    .then((res) => {
      return parseClientAPIResponse(res) as Promise<APISuccessResponse<T>>;
    })
    .catch((err) => {
      console.log(err);

      if (err instanceof APIError) {
        return {
          data: undefined,
          ok: false,
          status: err.status,
          statusText: err.statusText,
          customCode: err?.customCode || err.statusText,
          message: err?.message || err.statusText,
          code: err?.code || err.statusText,
        } as APIErrorResponse<T>;
      } else {
        return {
          data: undefined,
          ok: false,
          status: 500,
          statusText: "Internal Server Error",
          customCode: "Internal Server Error",
          message: err?.message,
          code: err?.message,
        } as APIErrorResponse<T>;
      }
    });
}

export async function parseClientAPIResponse<T>(res: Response) {
  try {
    let data;

    let contentType =
      res.headers.get("content-type")?.toLocaleLowerCase() || "";

    if (contentType.startsWith("application/json")) {
      data = await res.json();
    }

    if (!res.ok) {
      throw new APIError({
        data: undefined,
        status: res.status,
        ok: res.ok,
        message: data?.message || res.statusText,
        statusText: res?.statusText || res.statusText,
        customCode: data?.customCode || res.statusText,
        code: data?.code || res.statusText,
      });
    }

    return {
      data,
      ok: res?.ok,
      status: res?.status,
      statusText: res?.statusText,
      message: res?.statusText,
      customCode: res?.statusText,
      code: data?.code || res.statusText,
    } as APISuccessResponse<T>;
  } catch (err: unknown) {
    throw new APIError(err as any);
  }
}
