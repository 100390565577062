"use client";

import { clientFetcher } from "./client/utils";
import {
  APIDeleteProps,
  APIGetProps,
  APIPostProps,
  APIPutProps,
  APIResponse,
} from "./type";

export class ClientAPI {
  baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  get = async <T>({ path, headers }: APIGetProps): Promise<APIResponse<T>> => {
    return await clientFetcher<T>({
      url: `${this.baseUrl}${path}`,
      options: {
        method: "GET",
        headers: {
          ...headers,
          "Accept-Encoding": "gzip",
        },
      },
    });
  };

  post = async <S, T>({
    path,
    headers,
    body,
  }: APIPostProps<S>): Promise<APIResponse<T>> => {
    const isFormData =
      typeof body === "object" && body instanceof FormData ? true : false;

    return await clientFetcher<T>({
      url: `${this.baseUrl}${path}`,

      options: {
        method: "POST",
        headers: {
          ...(isFormData ? {} : { "Content-type": "application/json" }),
          ...(headers ?? {}),
          "Accept-Encoding": "gzip",
        },
        body: (isFormData ? body : JSON.stringify(body)) as BodyInit,
      },
    });
  };

  put = async <S, T>({
    path,
    headers,
    body,
  }: APIPutProps<S>): Promise<APIResponse<T>> => {
    const isFormData =
      typeof body === "object" && body instanceof FormData ? true : false;

    return await clientFetcher<T>({
      url: `${this.baseUrl}/${path}`,

      options: {
        method: "PUT",
        headers: {
          ...(isFormData ? {} : { "Content-type": "application/json" }),
          ...(headers ?? {}),
          "Accept-Encoding": "gzip",
        },
        body: (isFormData ? body : JSON.stringify(body)) as BodyInit,
      },
    });
  };

  delete = async <T>({
    path,
    headers,
  }: APIDeleteProps): Promise<APIResponse<T>> => {
    return await clientFetcher<T>({
      url: `${this.baseUrl}${path}`,

      options: {
        method: "DELETE",
        headers: {
          ...headers,
          "Accept-Encoding": "gzip",
        },
      },
    });
  };
}
