"use client";

import { SIGNIN_ROUTE } from "@/lib/constants/routes";
import { RCurrentUser } from "@dashboard/user/types";
import {
  ArrowLineRight,
  Chat,
  CircleHalfFill,
  File,
  GearSix,
  Monitor,
  Moon,
  Sun,
  UserCircle,
} from "@jengaicons/react";
import { Avatar, AvatarFallback, AvatarImage } from "@primitives/avatar";
import { Button } from "@primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@primitives/dropdown";
import { Link } from "@primitives/link";
import { Text } from "@primitives/text";
import { signOut, useSession } from "next-auth/react";
import { useTheme } from "next-themes";

const sectionOne = [
  {
    name: "",
    href: "",
    icon: <UserCircle size={16} />,
  },
  {
    name: "Settings",
    href: "/settings/general",
    icon: <GearSix size={16} />,
  },
];

const sectionTwo = [
  {
    name: "Docs",
    href: "/docs",
    icon: <File size={16} />,
  },
  {
    name: "Support",
    href: "#",
    icon: <Chat size={16} />,
  },
];

export default function UserMenu(props: {
  className?: string;
  user: RCurrentUser;
}) {
  const { theme, setTheme } = useTheme();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="hover:bg-pressed rounded-full border">
        <Avatar className="h-full w-full">
          <AvatarImage className="h-9 w-9" src={props.user?.avatarUrl} />
          <AvatarFallback
            className="h-9 w-9"
            name={props.user?.name as string}
          />
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="end"
        sideOffset={8}
        className="w-48 pt-0"
      >
        <DropdownMenuGroup className="bg-subdued p-4">
          <Text weight="medium">{props.user?.name}</Text>
          <Text color="soft" className="mt-1" variant="bodySm">
            {props.user?.displayName}
          </Text>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link
              href={`/${props.user?.name}`}
              className="focus-visible:ring-0"
              prefix={sectionOne[0].icon}
            >
              Your profile
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <Link
              className="gap-3 focus-visible:ring-0"
              href={sectionOne[1].href}
              prefix={sectionOne[1].icon}
            >
              {sectionOne[1]?.name}
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {sectionTwo.map((i) => (
            <DropdownMenuItem key={i.name}>
              <Link
                className="gap-3 focus-visible:ring-0"
                href={i.href}
                prefix={i.icon}
              >
                {i.name}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex items-center gap-2">
              {<CircleHalfFill size={16} />}
              <span>Theme</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent sideOffset={12}>
              <DropdownMenuItem
                onClick={() => {
                  setTheme("light");
                }}
                className={`flex items-center gap-2 ${
                  theme === "light" ? "bg-active" : ""
                }`}
              >
                <Sun size={16} />

                <span>Light</span>
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  setTheme("dark");
                }}
                className={`flex items-center gap-2 ${
                  theme === "dark" ? "bg-active" : ""
                }`}
              >
                <Moon size={16} />

                <span>Dark</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setTheme("system");
                }}
                className={`flex items-center gap-2 ${
                  theme === "system" ? "bg-active" : ""
                }`}
              >
                <Monitor size={16} />

                <span>System</span>
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuItem
            className="flex items-center gap-2"
            onClick={() => {
              signOut({
                redirect: true,
                callbackUrl: SIGNIN_ROUTE,
              });
            }}
          >
            <ArrowLineRight size={16} />
            <span>Logout</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
