import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { ComponentPropsWithRef, forwardRef, ReactNode } from "react";

const linkVariants = cva(
  "focus:ring-focus text-bodyMd inline-flex items-center gap-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset ",
  {
    variants: {
      color: {
        default: "text-default",
        strong: "text-strong",
        soft: "text-soft",
        primary: "text-primary",
        critical: "text-critical",
        success: "text-success",
        warning: "text-warning",
        disabled: "text-disabled",
        "on-primary": "text-on-primary",
      },
      underline: {
        true: `hover:underline underline-offset-2`,
      },
    },
  },
);

interface LinkBaseProps
  extends VariantProps<typeof linkVariants>,
    NextLinkProps {
  href: string;
  className?: string;
  children?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export interface LinkProps
  extends Omit<ComponentPropsWithRef<"a">, keyof LinkBaseProps>,
    LinkBaseProps {}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    href,
    className,
    color,
    children,
    prefix,
    suffix,
    underline,
    ...restProps
  } = props;

  return (
    <NextLink
      ref={ref}
      href={href}
      className={cn(
        linkVariants({ color, underline }),
        "visited:[inherit] [&>svg]:h-4 [&>svg]:w-4",
        className,
      )}
      {...restProps}
    >
      {prefix && prefix}
      {children}
      {suffix && suffix}
    </NextLink>
  );
});
