import { cn } from "@/lib/utils";
import { ComponentPropsWithRef, ReactNode } from "react";

export const Flex = (props: ComponentPropsWithRef<"div">) => {
  const { className, children, ...restProps } = props;
  return (
    <div className={cn(`flex`, className)} {...restProps}>
      {children}
    </div>
  );
};
export const Row = (props: ComponentPropsWithRef<"div">) => {
  const { className, children, ...restProps } = props;

  return (
    <div className={cn(`flex flex-row`, className)} {...restProps}>
      {children}
    </div>
  );
};

export const Column = (props: ComponentPropsWithRef<"div">) => {
  const { className, children, ...restProps } = props;

  return (
    <div className={cn(`flex flex-col`, className)} {...restProps}>
      {children}
    </div>
  );
};

export const Grid = (props: ComponentPropsWithRef<"div">) => {
  const { className, children, ...restProps } = props;

  return (
    <div className={cn(`grid`, className)} {...restProps}>
      {children}
    </div>
  );
};
