import { APIErrorResponse, APISuccessResponse } from "./type";

export interface APIError<T> extends APIErrorResponse<T> {}

export class APIError<T> {
  constructor({
    data,
    code,
    customCode,
    message,
    ok,
    status,
    statusText,
  }: APIErrorResponse<T>) {
    this.data = data;
    this.ok = ok;
    this.status = status;
    this.statusText = statusText;
    this.message = message;
    this.customCode = customCode;
    this.code = code;
  }
}
